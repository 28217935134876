import { useEffect, useState } from "react";
import "./GameCatalog.css";
import SORT_ORDER from "./SortOrder.json";
import ASPECT_RATIOS from "./AspectRatios.json";
import GameData from "./GameData.json";

interface CatalogSet {
  catalog: string;
  items: {
    image: string;
    link: string;
  }[];
}

declare global {
  interface Window {
    items: [string, string, string][];
  }
}

function GameCatalog() {
  const [catalogSets, setCatalogSets] = useState<CatalogSet[]>([]);
  const [page, setPage] = useState<string | null>(null);

  const albums = catalogSets.find(({ catalog }) => catalog === page)?.items;
  const specForRatio = ASPECT_RATIOS.find((spec) => spec.category === page);

  const changePage = (page: string) => {
    window.location.hash = page.toString();
    setPage(page);
  };

  useEffect(() => {
    const startPage = decodeURIComponent(window.location.hash.slice(1)) || null;
    setPage(startPage);

    setCatalogSets(() => {
      const items = GameData.data;
      const sets = items.reduce((acc, [catalog, link, image]) => {
        if (!(catalog && image)) {
          return acc;
        }

        let existingCatalog = acc.find((set) => set.catalog === catalog);
        if (!existingCatalog) {
          existingCatalog = { catalog, items: [] };
          acc.push(existingCatalog);
        }
        existingCatalog.items.push({ image, link });
        existingCatalog.items = shuffle(existingCatalog.items);
        return acc;
      }, [] as CatalogSet[]);
      sets.sort(
        ({ catalog }, { catalog: catalog2 }) =>
          SORT_ORDER.indexOf(catalog) - SORT_ORDER.indexOf(catalog2)
      );
      setPage(startPage || sets[sets.length - 1].catalog);
      return sets;
    });
  }, []);

  return (
    <div className="App container-fluid bg-dark">
      <div className="w-100 row px-2 my-3">
        <div className="col-6 col-sm-auto overflow-bar pb-4">
          {catalogSets.map(({ catalog }, i) => (
            <div className="row" key={i}>
              <div className="col-auto">
                <button
                  className={`text-muted menuItem ${
                    catalog === page ? "px-1" : ""
                  }`}
                  key={i}
                  onClick={() => changePage(catalog)}
                >
                  {catalog}
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="col row align-content-start w-100 pb-2 overflow-bar">
          {albums?.map(({ image, link }, j) => (
            <div key={j} className="col-lg-2 col-md-4 col-xs-6 mb-4">
              <a href={link}>
                <div
                  className="img-fluid img-thumbnail album w-100"
                  style={{
                    backgroundImage: `url(${image})`,
                    ...(specForRatio
                      ? { paddingBottom: `${specForRatio.ratio * 100}%` }
                      : {}),
                  }}
                ></div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function shuffle(array: any[]) {
  var currentIndex = array.length,
    temporaryValue: any,
    randomIndex: number;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default GameCatalog;
