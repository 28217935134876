import React from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import Tilty from "react-tilty";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { Row, Col, Nav } from "react-bootstrap";

import "./App.css";
import "./Gallery.css";

import { Gallery } from "./Gallery";
import tellerTop from "./picto/teller-top2.png";
import tellerBottom from "./picto/teller-bottom.png";
import diary from "./picto/diary.png";
import downloads from "./picto/downloads.png";
import gallery from "./picto/gallery.png";
import games from "./picto/games.png";
import home from "./picto/home.png";
import support from "./picto/support.png";
import bottombar from "./picto/bottomstripe.png";
import luminacam from "./picto/luminaborder.png";
// import guestbook2 from './picto/guestbookborder-2.png'
import guestbook from "./picto/guestbook.png";
import guestbookHeader from "./picto/guestbook-header.png";
import yellowborder from "./picto/yellowborder.png";
import sched from "./picto/sched.png";
import { Img, WaveSpans } from "./Components";
import GameCatalog from "./GameCatalog";

const MENU_BUTTON_HEIGHT = "50px";
const FORTUNETELLER_Width = "170px";

function MenuButton(props: {
  to?: string;
  src: typeof home;
  className?: string;
}) {
  return (
    <>
      <Link to={props.to || "/"} className={`nav-link ${props.className}`}>
        <Img
          src={props.src}
          alt={props.src.toString()}
          height={MENU_BUTTON_HEIGHT}
          width="auto"
        />
      </Link>
    </>
  );
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/hehe">
          <GameCatalog />
        </Route>
        <Route path="/">
          <div className="d-sm-none d-flex flex-column align-items-center justify-content-center h-100">
            <Tilty className="logo-img-mobile" />
            <div className="text-right mb-3">
              <span className="copyright">COPYRIGHT © 2004 ANGEL TOY</span>
            </div>
            <div className="text-center mx-2">
              <p className="wave">
                <WaveSpans text="Please view my webpage on desktop..." />
              </p>
            </div>
          </div>
          <div className="d-none d-sm-flex container-fluid">
            <div className="h-100 nav-bar">
              <div className="teller-container mt-3 d-flex flex-column align-items-center">
                <div>
                  <a
                    href="https://www.twitch.tv/angelfoood"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      src={tellerTop}
                      alt="tellertop"
                      width={FORTUNETELLER_Width}
                      height="auto"
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.twitch.tv/angelfoood"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Img
                      src={tellerBottom}
                      alt="tellerbottom"
                      width={FORTUNETELLER_Width}
                      height="auto"
                    />
                  </a>
                </div>
              </div>
              <Nav
                defaultActiveKey="/"
                className="px-2 flex-column menu-button-container"
              >
                <MenuButton className={"menu-button"} src={home} to="/" />
                <Nav.Link
                  className="ml-auto menu-button"
                  href="//youtube.com/c/angelfoood"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img
                    src={diary}
                    alt="diary"
                    height={MENU_BUTTON_HEIGHT}
                    width="auto"
                  />
                </Nav.Link>
                {/* <MenuButton className={'ml-auto menu-button'} src={diary} to="/diary" /> */}
                <MenuButton
                  className={"menu-button"}
                  src={gallery}
                  to="/gallery"
                />
                <MenuButton
                  className={"ml-auto menu-button grayout"}
                  src={games}
                />
                <MenuButton
                  className={"menu-button"}
                  src={downloads}
                  to="/download"
                />
                <Nav.Link
                  className="ml-auto menu-button"
                  href="//ko-fi.com/angelfoood"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img
                    src={support}
                    alt="support"
                    height={MENU_BUTTON_HEIGHT}
                    width="auto"
                  />
                </Nav.Link>
              </Nav>
            </div>
            <div className="w-100 center-area">
              <Row className="mt-3 no-gutters">
                <Col xs="12" className="mx-2 col-md center-continer">
                  <div className="center-world position-absolute h-100 w-100">
                    <Img src={yellowborder} height="100%" width="100%" />
                  </div>
                  <Switch>
                    <Route path="/games">
                      <Games />
                    </Route>
                    <Route path="/diary">
                      <Diary />
                    </Route>
                    <Route path="/gallery">
                      <Gallery />
                    </Route>
                    <Route path="/download">
                      <Download />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                </Col>
                <Col xs="3" className="side-area mr-3">
                  <div className="luminacam mb-2">
                    <Img alt="" src={luminacam} />
                  </div>
                  <div className="guestbook d-flex flex-column align-items-stretch justify-content-center">
                    <div style={{ zIndex: 5 }}>
                      <Img
                        alt=""
                        src={guestbookHeader}
                        width="100%"
                        height="auto"
                      />
                    </div>
                    <div>
                      <div className="guestbook-overlay w-100">
                        <Img
                          alt=""
                          src={guestbook}
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="tweets-container w-100">
                        <div className="tweets">
                          <TwitterTweetEmbed tweetId="1851322428758057004" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="w-100 position-absolute bottom-bar">
              <Img className="w-100" alt="bb" src={bottombar} />
              <div
                className="position-absolute logo d-flex"
                onClick={() => {
                  window.open("//www.twitch.tv/angelfoood");
                }}
              >
                <Tilty
                  className="logo-img"
                  reverse={true}
                  speed={666}
                  perspective={666}
                ></Tilty>
                <span className="position-absolute copyright">
                  COPYRIGHT © 2004 ANGEL TOY
                </span>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

function Home() {
  return (
    <div className="mx-auto pink-world pink text-center d-flex flex-column">
      <h2 className="wave my-3">
        <WaveSpans text="welcome to angelfoood.com"></WaveSpans>
      </h2>
      <a href="//www.twitch.tv/angelfoood">
        <Img className="poin" src={sched} width="90%" height="auto" />
      </a>
    </div>
  );
}

function Diary() {
  return (
    <div className="mx-auto pink-world pink text-center d-flex flex-column align-items-center">
      <h2 className="wave my-3">
        <WaveSpans text="youtube" />
      </h2>
      <iframe
        title="download"
        width="90%"
        height="82%"
        src="https://www.youtube.com/embed/videoseries?list=UU3JaBZxdfGWQX5zpJQMF3jA"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

function Games() {
  return (
    <div className="pink text-center">
      <h2>Games</h2>
    </div>
  );
}

function Download() {
  return (
    <iframe
      title="download"
      width="100%"
      height="100%"
      src="https://www.youtube.com/embed/VBVFUZJmfCY?autoplay=1"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  );
}
