import React from 'react';

export const Img: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ ...props }) => {
  return (
    <img {...props} draggable={false} alt={props.alt || props.src || ''} />
  );
};

export function WaveSpans (props: {text: string; offset?: number}) {
  return <>{Array.from(props.text).map((c,i)=><span style={{ "--animation-order": i+(props.offset || 0) } as any}>{c.trim() ? c : <>&nbsp;</>}</span>)}</>;
}
